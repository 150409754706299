import { stakingOpts } from '@pgl-apps/kap-stake/hooks'
import { stakingKeys } from '@pgl-apps/shared/constants'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import { Pool } from '../../layouts'

interface Props {
  token: Record<string, any>
  staking: Record<string, any>
  onStake: (arg1: keyof typeof stakingOpts) => void
}

export const PoolBuilder = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { token, staking, onStake } = props
  const { kap, tvl } = token

  return (
    <Pool
      hookKey={stakingKeys.kapEth}
      icon={stakingOpts.kapEth.icon}
      label={stakingOpts.kapEth.label}
      price={kap.price && addNumPunctuation(kap.price, 6)}
      apy={staking.kapEth.maxApr}
      tvl={tvl}
      onStake={onStake}
    />
  )
}
