import type { ReactNode } from 'react'

interface Props {
  className?: string
  children: ReactNode
}

export const Section = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { className = 'flex flex-col gap-4', children } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <section
      className={`
        ${className}
      `}
    >
      {children}
    </section>
  )
}
