import { useSelector } from 'react-redux'

import { modalTypes } from '@pgl-apps/kap-stake/constants'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { showModal } from '@pgl-apps/kap-stake/redux'
import logo from '@pgl-apps/shared-images/logos/odyn-logo.svg'
import { ReactComponent as Plus } from '@pgl-apps/shared-images/svgr/icons/plus.svg'
import { Btn, CtaBtn } from '@pgl-apps/shared/components'
import { actionStatuses, getActionStatusKey } from '@pgl-apps/shared/helpers'

import { AccountDisplay } from '../AccountDisplay/AccountDisplay'

export const PrimaryNav = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletIds = useSelector((store: IRootState) => store.wallet.ids)
  const walletPending = useSelector(
    (store: IRootState) =>
      store.wallet[getActionStatusKey('addWallet', actionStatuses.PENDING)]
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleClick = () => {
    dispatch(showModal(modalTypes.wallet))
  }

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className="flex justify-between items-center px-6 py-4">
      <div className="flex items-center">
        {/* <div className="flex w-6 mr-5">
          <MenuTrigger
            onClick={() => setMenuActive((prev) => !prev)}
            label="Toggle nav"
            isActive={menuActive}
          />
        </div> */}
        <div className="w-40">
          <img src={logo} alt="" />
        </div>
      </div>
      {walletIds.length > 0 ? (
        <AccountDisplay walletId={walletIds[0]} />
      ) : (
        <div>
          <Btn
            onClick={handleClick}
            state={walletPending ? 'disabled' : 'default'}
            label={walletPending ? 'Pending...' : 'Add Wallet'}
            leftIcon={<Plus className="" />}
            size="sm"
          />
        </div>
      )}
    </div>
  )
}
