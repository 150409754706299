import { ethers } from 'ethers'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useNotifications, useWallet } from '@pgl-apps/kap-stake/hooks'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { useMountEffect } from '@pgl-apps/shared/hooks'
import { NotificationTypes } from '@pgl-apps/shared/types'

export const WalletMonitor = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const chainId = useSelector((store: IRootState) =>
    store.wallet.provider ? store.wallet.walletNetwork : null
  )

  // --------------------- ===
  //  HOOKS
  // ---------------------
  const { connect } = useWallet()
  const { addNotification } = useNotifications()

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    // Auto connect wallet if it exists
    const checkProvider = async () => {
      const wind = window as any
      if (wind.ethereum) {
        const provider = new ethers.providers.Web3Provider(wind.ethereum)
        const addresses = await provider.listAccounts()
        return addresses && addresses.length
      }
      return false
    }
    checkProvider().then((hasAddress) => {
      if (hasAddress) {
        connect('MetaMask')
      }
    }) // If they have approved an address can we assume it's MetaMask
  })

  useEffect(() => {
    // Make sure user is on ethChainId
    if (chainId && chainId !== Number(config.chainId)) {
      addNotification({
        message: `Please switch to the ${config.network.toLocaleUpperCase()} network.`,
        type: NotificationTypes.info,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, dispatch]) // not addNotification func

  // --------------------- ===
  //  RENDER
  // ---------------------
  return null
}
