import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import { useEffect, useRef, useState } from 'react'

import { useMountEffect } from '@pgl-apps/shared/hooks'

import styles from './index.module.scss'

gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)

interface Props {
  r: number
  x: number
  y: number
  origin?: string
  value: number
  min: number
  max: number
  setValue: (a: number) => void
}

const minRotation = 0
const maxRotation = 180

const getRange = () => maxRotation - minRotation

const valToDeg = (value: number, min: number, max: number) => {
  const range = getRange()
  const pct = (value - min) / (max - min)
  return pct * range + minRotation
}

const degToValue = (deg: number, min: number, max: number) => {
  const pct = (deg - minRotation) / (maxRotation - minRotation)
  const valRange = max - min
  return Math.round(pct * valRange + min)
}

export const SvgKnob = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { r, x, y, origin = '0px 0px', value, min, max, setValue } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const knobRef = useRef(null)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDragging, setIsDragging] = useState(false)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    const handleDrag = (deg: number) => {
      const val = degToValue(deg, min, max)
      setValue(val)
    }

    const tracker = InertiaPlugin.track(knobRef.current, 'rotation')[0] // allows getVolicity to work

    const draggable = Draggable.create(knobRef.current, {
      type: 'rotation',
      inertia: true,
      throwResistance: 5000,
      overshootTolerance: 0.1,
      bounds: {
        minRotation,
        maxRotation,
      },
      onDragStart: () => {
        setIsDragging(true)
      },
      onDrag: () => handleDrag(draggable[0].rotation),
      onDragEnd: () => {
        const velocity = Math.round(
          InertiaPlugin.getVelocity(knobRef.current, 'rotation')
        )

        if (velocity === 0) {
          setIsDragging(false)
        }
      },
      onThrowUpdate: () => handleDrag(draggable[0].rotation),
      onThrowComplete: () => {
        setIsDragging(false)
      },
    })
  })

  useEffect(() => {
    if (!isDragging) {
      const deg = valToDeg(value, min, max)
      gsap.to(knobRef.current, {
        rotation: deg,
        duration: 0.3,
      })
    }
  }, [isDragging, value, min, max])

  // --------------------- ===
  //  RENDER
  // ---------------------
  // TODO? part 1/2
  // const rectWidth = r * 0.75
  // const rectHeight = 2
  // const rectX = x - (r * 2 - rectWidth) / 2
  // const spacer = rectHeight * 2
  return (
    <g
      className={`${styles.knob} ${isDragging ? styles.knob__active : ''}`}
      style={{
        transformOrigin: origin,
      }}
      ref={knobRef}
      tabIndex={0}
    >
      <circle className={styles.circle} r={r} cx={x} cy={y} />
      {
        // TODO? part 2/2
        // <rect className={styles.rect} x={rectX} y={rectHeight / 2 + y} width={rectWidth} height={rectHeight} />
        // <rect className={styles.rect} x={rectX} y={(rectHeight / 2) - spacer + y} width={rectWidth} height={rectHeight} />
        // <rect className={styles.rect} x={rectX} y={(rectHeight / 2) + spacer + y} width={rectWidth} height={rectHeight} />
      }
    </g>
  )
}
