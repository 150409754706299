import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import {
  ModalContent,
  NotificationFactory,
  StakingMonitor,
  TransactionFooter,
  WalletMonitor,
} from '@pgl-apps/kap-stake/components'
import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { routeConfig } from '@pgl-apps/kap-stake/router'
import { ModalTrap } from '@pgl-apps/shared/components'
import { focusModal, initLogRocket } from '@pgl-apps/shared/helpers'
import { useMountEffect } from '@pgl-apps/shared/hooks'

const App = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const isModalVisible = useSelector(
    (store: IRootState) => store.modal.isVisible
  )
  const transactions = useSelector(
    (store: IRootState) => store.transactions.list
  )

  // --------------------- ===
  //  HANDLERS
  // ---------------------
  const handleModalTrapFocus = () => {
    focusModal()
  }

  // --------------------- ===
  //  HOOKS
  // ---------------------
  useMountEffect(() => {
    // for now log all users
    initLogRocket()
  })

  // --------------------- ===
  //  RENDER
  // ---------------------
  const element = useRoutes(routeConfig)
  // kap-app class is used for namespacing css modules
  return (
    <div className="kap-app dark">
      {element}
      <WalletMonitor />
      <StakingMonitor />
      <NotificationFactory />
      {isModalVisible && <ModalTrap onFocus={handleModalTrapFocus} />}
      <ModalContent />
      {isModalVisible && <ModalTrap onFocus={handleModalTrapFocus} />}

      {transactions.length > 0 && <TransactionFooter />}
    </div>
  )
}

export default App
