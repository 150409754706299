import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useMountEffect } from '@pgl-apps/shared/hooks'

import styles from './index.module.scss'

gsap.registerPlugin(Draggable)

const minRotation = -45
const maxRotation = 45

const getRange = () => maxRotation - minRotation

const valToDeg = (value: number, min: number, max: number) => {
  const range = getRange()
  const pct = (value - min) / (max - min)
  return pct * range + minRotation
}

const degToValue = (deg: number, min: number, max: number) => {
  const pct = (deg - minRotation) / (maxRotation - minRotation)
  const valRange = max - min
  return Math.round(pct * valRange + min)
}

const buildTicks = (min: number, max: number, increment: number) => {
  const ticks = []
  for (let i = min; i <= max; i += increment) {
    // const range = getRange();
    // const pct = (i - min) / (max - min);
    const deg = valToDeg(i, min, max)
    ticks.push({
      label: i,
      deg,
    })
  }
  return ticks
}

interface Props {
  value: number
  min: number
  max: number
  increment: number
  setValue: (a: number) => void
}

export const Nob = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { value, min, max, increment, setValue } = props

  const ticks = useMemo(
    () => buildTicks(min, max, increment),
    [min, max, increment]
  )

  // --------------------- ===
  //  REFS
  // ---------------------
  const nobRef = useRef(null)

  // --------------------- ===
  //  STATE
  // ---------------------
  const [isDragging, setIsDragging] = useState(false)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    const handleDrag = (deg: number) => {
      const val = degToValue(deg, min, max)
      setValue(val)
    }

    const draggable = Draggable.create(nobRef.current, {
      type: 'rotation',
      bounds: {
        minRotation,
        maxRotation,
      },
      onDragStart: () => {
        setIsDragging(true)
      },
      onDrag: () => handleDrag(draggable[0].rotation),
      onDragEnd: () => {
        setIsDragging(false)
      },
    })
  })

  useEffect(() => {
    if (!isDragging) {
      const deg = valToDeg(value, min, max)
      gsap.to(nobRef.current, {
        rotation: deg,
        duration: 0.3,
      })
    }
  }, [isDragging, value, min, max])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.content}>
          <span className={styles.number}>{value}</span>
          <span>Weeks</span>
        </div>
        {ticks.map((tick) => (
          <div
            className={styles.tick}
            style={{ transform: `rotate(${tick.deg}deg)` }}
            key={tick.label}
          >
            <div className={styles.tickMark} />
          </div>
        ))}
        <div ref={nobRef} className={styles.nob}>
          <div className={styles.trigger} />
        </div>
      </div>
    </div>
  )
}
