import React from 'react'

import { Label } from '@pgl-apps/shared/components'
import { useMountEffect } from '@pgl-apps/shared/hooks'

import { ArrowBtn } from './ArrowBtn/ArrowBtn'
import { SvgKnob } from './SvgKnob/SvgKnob'
import { SvgRect } from './SvgRect/SvgRect'
import { SvgWrapper } from './SvgWrapper/SvgWrapper'
import styles from './index.module.scss'

interface Props {
  min: number
  max: number
  onChange: (arg1: number) => void
  label: string
  value: number
  title: string
}

const r = 12
const w = 200
const h = w / 2
const width = w + 2 * r
const height = h + 2 * r

const rectW = 8
const rectH = 2

const buildArr = (min: number, max: number) => {
  const arr = []
  for (let i = min; i <= max; i += 1) {
    arr.push(i)
  }
  return arr
}

export const DurationSlider = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { min, max, onChange, label, value, title } = props

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    // set value to max on load
    onChange(max)
  })

  // --------------------- ===
  //  RENDER
  // ---------------------
  const arr = buildArr(min, max)
  return (
    <div className="relative flex justify-content-center">
      <SvgWrapper x={-r} y={-r} width={width} height={height}>
        {arr.map((v, i) => (
          <SvgRect
            width={rectW}
            height={rectH}
            x={0}
            y={h - rectH / 2}
            rotate={(i / (arr.length - 1)) * 180}
            origin={`${w / 2}px ${h - rectH / 2}px`} // it says px, but it's actually svg coords
            isActive={v <= value}
            opacity={value / (max - min)}
            key={v}
          />
        ))}
        <SvgKnob
          r={r}
          x={rectW / 2}
          y={h - rectH / 2}
          origin={`${w / 2 + 8}px ${r}px`} // not sure why 8? (r / 2) + ?
          value={value}
          min={min} // TODO pull from contract?
          max={max} // TODO pull from contract?
          setValue={(val) => onChange(val)}
        />
      </SvgWrapper>
      <div className={styles.countWrapper}>
        <Label>{title}</Label>
        <div className={styles.week}>
          <div className={styles.arrow}>
            <ArrowBtn
              isLeft
              isDisabled={value <= min}
              onClick={() => onChange(value - 1)}
            />
          </div>
          <div className={styles.weekCount}>{`${value} ${label}${
            value > 1 ? 's' : ''
          }`}</div>
          <div className={styles.arrow}>
            <ArrowBtn
              isDisabled={value >= max}
              onClick={() => onChange(value + 1)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
