import React from 'react'

import { ReactComponent as EthIcon } from '@pgl-apps/shared-images/svgr/icons/eth.svg'

import styles from './index.module.scss'

interface Props {
  walletId: string
}

export const AccountDisplay = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { walletId } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const { length } = walletId
  const split = 4
  return (
    <div className={`${styles.wrapper} bg-kap-white text-kap-black`}>
      <EthIcon className={styles.icon} />
      <span className={styles.id}>
        <span className={styles.idL}>
          {walletId.substring(0, length - split)}
        </span>
        <span>{walletId.substring(length - split, length)}</span>
      </span>
    </div>
  )
}
