import React, { useState } from 'react'

import { Balance } from './Balance'
import { FooterBtns } from './FooterBtns'
import { Input } from './Input'
import { Nob } from './Nob'
import { TabBtn } from './TabBtn'
import styles from './index.module.scss'

const tabs = [
  {
    label: 'KAP',
  },
  {
    label: 'KAP ETH/LP',
  },
]

const min = 5
const max = 15
const increment = 1

export const StakingModule = () => {
  // --------------------- ===
  //  STATE
  // ---------------------
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const [duration, setDuration] = useState(max)

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <TabBtn
              key={tab.label}
              label={tab.label}
              isCurrent={tab === currentTab}
              onClick={() => setCurrentTab(tab)}
            />
          ))}
        </div>
        <Balance value={123456} />
      </div>
      <div className={styles.body}>
        <div className={styles.bodyItem}>
          <Input unit={currentTab.label} />
        </div>
        <div className={styles.bodyItem}>
          <Nob
            value={duration}
            min={min}
            max={max}
            increment={increment}
            setValue={(val) => setDuration(val)}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <FooterBtns />
      </div>
    </div>
  )
}
