import React from 'react'

import styles from './index.module.scss'

interface Props {
  x: number
  y: number
  width: number
  height: number
  rotate: number
  origin?: string
  isActive: boolean
  opacity: number // between 0 & 1
}

export const SvgRect = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    x,
    y,
    width,
    height,
    rotate,
    origin = '0px 0px',
    isActive,
    opacity,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <rect
      className={`${styles.rect} ${isActive ? styles.rect__active : ''}`}
      style={{
        opacity: isActive ? opacity : 1,
      }}
      x={x}
      y={y}
      width={width}
      height={height}
      transform={`rotate(${rotate})`}
      transform-origin={origin}
    />
  )
}
