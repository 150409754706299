import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import type { IRootState } from '@pgl-apps/kap-stake/redux'
import { useAppDispatch } from '@pgl-apps/kap-stake/redux'
import { kapEth, rewards, tokens, vestings } from '@pgl-apps/kap-stake/redux'
import { config } from '@pgl-apps/shared/api'
import { useMountEffect, usePrevious } from '@pgl-apps/shared/hooks'

// [TODO] Disable single staking based on global config
export const StakingMonitor = () => {
  // --------------------- ===
  //  STORE
  // ---------------------
  const dispatch = useAppDispatch()
  const walletId = useSelector((store: IRootState) => store.wallet.ids[0])
  const { max: maxLockPeriod } = useSelector(
    (store: IRootState) => store.kapEth.lockPeriodLimits
  ) // used as a reference to monitor if params of stakingContract were loaded
  const prevWalletId = usePrevious(walletId)

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    // load tokens params
    dispatch(tokens.actions.fetchBaseParams(config.addresses.tokens.kap))
    dispatch(
      tokens.actions.fetchBaseParams(config.addresses.tokens.kapEthLP, true)
    )
    // load tokens balances
    dispatch(
      tokens.actions.fetchBalances(config.addresses.tokens.kapEthLP, [
        config.addresses.staking.kapEth,
      ])
    )

    // load staking contract params
    // dispatch(kap.actions.fetchContractData())
    dispatch(kapEth.actions.fetchContractData())
  })

  useEffect(() => {
    if (!walletId || maxLockPeriod.toNumber() == 0) return

    /**
     * @dev Load all staking agreements only after the main params(like aprDivider) of staking contract are fully loaded.
     */
    // dispatch(kap.actions.fetchStakingAgreements(walletId))
    dispatch(kapEth.actions.fetchStakingAgreements(walletId))
  }, [walletId, maxLockPeriod])

  useEffect(() => {
    if (!walletId) return

    dispatch(
      tokens.actions.fetchBalances(config.addresses.tokens.kap, [walletId])
    )
    dispatch(
      tokens.actions.fetchBalances(config.addresses.tokens.kapEthLP, [walletId])
    )
    dispatch(rewards.actions.fetchRewardsLockAgreements(walletId))
    dispatch(vestings.actions.fetchVestingAgreements(walletId))
  }, [walletId])

  // wallet disconnect handler
  useEffect(() => {
    if (prevWalletId && !walletId) {
      dispatch(kapEth.actions.clearStakingAgreements())
      dispatch(rewards.actions.clearRewardsLockAgreements())
      dispatch(vestings.actions.clearVestingAgreements())
    }
  }, [prevWalletId, walletId])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return null
}
