import React from 'react'

import { FooterBtn } from '../FooterBtn'
import styles from './index.module.scss'

export const FooterBtns = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className={styles.wrapper}>
      <div className="relative">
        <div className={styles.bg} />
        <div className={styles.btns}>
          <FooterBtn label="Cancel" onClick={() => null} />
          <FooterBtn label="Stake" onClick={() => null} />
        </div>
      </div>
    </div>
  )
}
