import React, { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from './index.module.scss'

interface Props {
  value: number
}

export const Balance = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { value } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  const id = useMemo(uuidv4, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <span className={styles.label} id={id}>
          Balance
        </span>
        <span className={styles.value} aria-labelledby={id}>
          {`${value}KAP`}
        </span>
      </div>
      <div className={styles.angle} />
    </div>
  )
}
