import React from 'react'

import styles from './index.module.scss'

interface Props {
  label: string
  onClick: () => void
}

export const FooterBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, onClick } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button className={styles.btn} type="button" onClick={onClick}>
      {label}
    </button>
  )
}
