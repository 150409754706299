import React from 'react'

import arrow from '@pgl-apps/shared-images/icons/arrow-right-black.svg'

import styles from './index.module.scss'

interface Props {
  isLeft?: boolean
  isDisabled?: boolean
  onClick: () => void
}

export const ArrowBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { isLeft = false, isDisabled = false, onClick } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      className={`
        ${styles.btn}
        ${isDisabled ? styles.btn__disabled : ''}
        ${isLeft ? styles.btn__left : ''}
      `}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
    >
      <img src={arrow} alt={`${isLeft ? 'increment' : 'decrement'} weeks`} />
    </button>
  )
}
