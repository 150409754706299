import type { stakingOpts } from '@pgl-apps/kap-stake/hooks'
import { Btn, Heading, Label } from '@pgl-apps/shared/components'
import { addNumPunctuation } from '@pgl-apps/shared/helpers'

import styles from './index.module.scss'

interface Props {
  hookKey: keyof typeof stakingOpts
  icon: string
  label: string
  price: string | number
  apy: string
  tvl: string
  onStake: (arg1: keyof typeof stakingOpts) => void
}

export const Pool = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { hookKey, icon, label, price, apy, tvl, onStake } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div className={`${styles.wrapper} border border-theme-tableHeaderBg`}>
      <div className={styles.header}>
        <div className="flex items-center">
          <div className={styles.icon}>
            <img src={icon} alt="" />
          </div>
          <span>
            <Heading type="paragraph" tag="h4" className="text-white mb-0">
              {label}
            </Heading>
          </span>
        </div>
        <Label>1 KAP = {`$${price ?? '-'} USD`}</Label>
      </div>
      <p className={styles.info}>
        <Label>Max APR&nbsp;</Label>
        <span>{`${apy ?? ' - '}%`}</span>
      </p>
      <p className={styles.info}>
        <Label>TVL&nbsp;</Label>
        <span>{`$${addNumPunctuation(tvl, 2)}`}</span>
      </p>
      <div className="mt-12 text-center flex flex-col w-full">
        <Btn
          onClick={() => onStake(hookKey)}
          label="Stake Now"
          size="sm"
          type="solid"
        />
      </div>
    </div>
  )
}
