import React from 'react'

import styles from './index.module.scss'

interface Props {
  unit: string
}

export const Input = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { unit } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <label className="flex flex-column">
      <span className={styles.label}>Enter Amount</span>
      <div className="flex align-items-end">
        <input className={`mt-2 mr-3 ${styles.input}`} type="number" />
        <span className={styles.label}>{unit}</span>
      </div>
    </label>
  )
}
