import React from 'react'

import styles from './index.module.scss'

interface Props {
  label: string
  isCurrent: boolean
  onClick: () => void
}

export const TabBtn = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { label, isCurrent, onClick } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      type="button"
      className={`${styles.btn} ${isCurrent ? styles.btn__current : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}
