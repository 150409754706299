import { PrimaryNav } from './PrimaryNav/PrimaryNav'
import { SecondaryNav } from './SecondaryNav/SecondaryNav'

export const Header = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <>
      <header className="divider-b relative w-full">
        <PrimaryNav />
      </header>
      <div className="mb-12 text-center text-sm bg-kap-neon p-2 font-normal text-kap-black">
        Emissions are now live!
      </div>
      <div className="container mx-auto">
        <div className="divider-b pb-6 mb-12">
          <SecondaryNav />
        </div>
      </div>
    </>
  )
}
