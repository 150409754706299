import type { ReactNode } from 'react'

interface Props {
  x?: number
  y?: number
  width: number
  height: number
  children: ReactNode
}

export const SvgWrapper = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { x = 0, y = 0, width, height, children } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return <svg viewBox={`${x} ${y} ${width} ${height}`}>{children}</svg>
}
