import type { SVGR } from '@pgl-apps/shared/types'

import styles from './index.module.scss'

type Type = 'button' | 'submit'

interface Props {
  onClick: () => void
  label: string
  isDisabled?: boolean
  type?: Type
  Icon?: SVGR // svgr icon
}

export const CtaFlat = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const {
    onClick,
    label,
    isDisabled = false,
    type = 'button',
    Icon = null,
  } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      className={`${styles.btn} ${isDisabled ? styles.btn__disabled : ''}`}
      onClick={() => !isDisabled && onClick()}
      type={type}
    >
      {Icon && <Icon className={styles.icon} />}
      <span className={styles.label}>{label}</span>
    </button>
  )
}
