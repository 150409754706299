import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { Heading } from '@pgl-apps/shared/components'
import { formatCash } from '@pgl-apps/shared/helpers'
import { useMountEffect } from '@pgl-apps/shared/hooks'

import dragger from './dragger.svg'
import styles from './index.module.scss'

gsap.registerPlugin(Draggable)

const totalEpochs = 1040
const yearDistribution = 50000000
const weekDistribution = yearDistribution / totalEpochs

// distribute 2,500,000 KAP per year for the next 20 years
export const EmissionSchedule = () => {
  // --------------------- ===
  //  STATE
  // ---------------------
  // Staking start date on 2023-09-01
  const startDate = moment('2023-09-01')
  const [progress, setProgress] = useState(0) // 0 - 100
  const [isDragging, setIsDragging] = useState(false)

  const [boundsRef, setBoundsRef] = useState<HTMLDivElement>(null)
  const [knobRef, setKnobRef] = useState<HTMLDivElement>(null)

  // --------------------- ===
  //  METHODS
  // ---------------------
  const getBoundsWidth = () => {
    if (boundsRef && knobRef) {
      return boundsRef.offsetWidth - knobRef.offsetWidth
    }
    return 1000
  }
  const initProgress = () => {
    const _progress = (week / totalEpochs) * 100
    setProgress(_progress)
  }

  const today = useMemo(() => moment(), [])
  const week = moment().diff(startDate, 'weeks') + 1

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    const onResize = () => {
      setIsDragging((prev) => !prev) // should work well enough
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  useEffect(() => {
    function handleDrag() {
      const _progress = (this.x / getBoundsWidth()) * 100
      setProgress(_progress)
    }
    if (knobRef && boundsRef) {
      Draggable.create(knobRef, {
        type: 'x',
        bounds: boundsRef,
        onDragStart: () => {
          setIsDragging(true)
        },
        onDragEnd: () => {
          setIsDragging(false)
        },
        onDrag: handleDrag,
        zIndexBoost: false,
      })
      initProgress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knobRef, boundsRef]) // only knobRef and boundsRef

  useEffect(() => {
    if (!isDragging) {
      gsap.set(knobRef, { x: progress * (getBoundsWidth() / 100) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, progress]) // only isDragging and progress and width

  return (
    <div className="m-2 relative">
      <div className="flex justify-between items-center mb-11 flex-col md:flex-row">
        <div className="flex items-center flex-col md:flex-row">
          <Heading tag="h3" type="section" className="mr-0 md:mr-4">
            Emission Schedule
          </Heading>
          <Heading tag="h4" type="paragraph" color="muted">
            {today.format('MMM Do YYYY')}
          </Heading>
        </div>

        <div className="flex items-end">
          <Heading tag="h3" type="paragraph" color="muted" className="mr-4">
            EPOCH
          </Heading>
          <Heading tag="h3" type="paragraph" color="muted">
            <span className="text-theme-base">{week}</span>/{totalEpochs}
          </Heading>
        </div>
      </div>

      <div className={styles.progressWrapper} ref={setBoundsRef}>
        <div className="flex">
          <div
            className={styles.blueBorder}
            style={{ width: `${progress}%` }}
          />
          <div className="grow" />
        </div>

        <div className="flex mb-4">
          <div
            className={styles.blueGradient}
            style={{ width: `${progress}%` }}
          />
          <div className="grow" />
        </div>

        <div className={styles.progress}>
          <span className={`${styles.startLabel} bg-theme-containerBg`}>
            START
          </span>
          <div className="grow flex items-center">
            <div className={styles.active} style={{ width: `${progress}%` }} />
            <div className={styles.inactive} />
          </div>
          <span className={`${styles.endLabel} bg-theme-containerBg`}>END</span>
        </div>

        <div ref={setKnobRef} className={styles.knobWrapper}>
          <div className={`${styles.indicator} bg-theme-containerBg`}>
            <div className={styles.label}>Distributing</div>
            <div className={styles.kapAmount}>
              {formatCash(weekDistribution)}
            </div>
            <div className={styles.totalEmission}>
              {formatCash(week * weekDistribution)} of&nbsp;
              {formatCash(yearDistribution)} KAP
              <br />
              Distributed
            </div>
          </div>
          <div
            className={`${styles.knob} bg-theme-containerBg flex items-center justify-center px-1`}
          >
            <img src={dragger} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
