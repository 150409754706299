import React from 'react'

import { ReactComponent as Arrow } from '@pgl-apps/shared-images/svgr/icons/arrow-right.svg'

import styles from './index.module.scss'

type Type = 'button' | 'submit'

interface Props {
  onClick: () => void
  label: string
  isDisabled?: boolean
  type?: Type
}

export const CtaPrimary = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onClick, label, isDisabled = false, type = 'button' } = props

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button
      className={`${styles.btn} ${isDisabled ? styles.btn__disabled : ''}`}
      onClick={() => !isDisabled && onClick()}
      type={type}
    >
      <span className="relative">{label}</span>
      <Arrow className={styles.icon} />
    </button>
  )
}
