import { Outlet } from 'react-router-dom'

import circles from '@pgl-apps/shared-images/decoration/accent-circles.svg'
import dashes from '@pgl-apps/shared-images/decoration/accent-dashes.svg'
import { mainContentId } from '@pgl-apps/shared/helpers'

import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import styles from './index.module.scss'

export const MainLayout = () => {
  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <div
      className={`relative pb-16 min-h-screen overflow-hidden ${styles.main}`}
    >
      <div className="pointer-events-none opacity-25">
        <img
          className={`${styles.circle} ${styles.circle__left}`}
          src={circles}
          alt=""
        />
        <img
          className={`${styles.circle} ${styles.circle__right}`}
          src={circles}
          alt=""
        />
        <img
          className={`${styles.dashes} ${styles.dashes__left}`}
          src={dashes}
          alt=""
        />
        <img
          className={`${styles.dashes} ${styles.dashes__right}`}
          src={dashes}
          alt=""
        />
      </div>
      <div className={styles.primary}>
        <Header />
        <div
          id={mainContentId}
          className="container mx-auto flex flex-col gap-4"
        >
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}
