import { gsap } from 'gsap'
import React, { useEffect, useRef } from 'react'

import { SrOnly } from '@pgl-apps/shared/components'
import { useMountEffect } from '@pgl-apps/shared/hooks'

import styles from './index.module.scss'

interface Props {
  onClick: () => void
  label: string
  isActive: boolean
}

const duration = 0.5
const easeSvg = 'elastic.out(1, 0.5)'
const easeDots = 'power2.out'

export const MenuTrigger = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onClick, label, isActive } = props

  // --------------------- ===
  //  REFS
  // ---------------------
  const svg = useRef(null)
  const tlSvg = useRef(
    gsap.timeline({
      paused: true,
      defaults: {
        duration: duration * 2,
        ease: easeSvg,
      },
    })
  )
  const tlDots = useRef(
    gsap.timeline({
      paused: true,
      defaults: {
        duration,
        ease: easeDots,
      },
    })
  )

  // --------------------- ===
  //  EFFECTS
  // ---------------------
  useMountEffect(() => {
    if (!svg.current) {
      // linter
      return
    }
    const { children } = svg.current

    tlSvg.current
      // rotate
      .to(svg.current, {
        rotate: '135deg',
      })
    tlDots.current
      // lines
      .addLabel('start')
      .to(
        children[0],
        {
          attr: {
            y2: 45,
          },
        },
        'start'
      )
      .to(
        children[1],
        {
          attr: {
            x1: 5,
          },
        },
        'start'
      )
      .to(
        children[2],
        {
          attr: {
            y1: 5,
          },
        },
        'start'
      )
      .to(
        children[3],
        {
          attr: {
            x2: 45,
          },
        },
        'start'
      )
      // circles
      .to(
        children[4],
        {
          attr: {
            cx: 10,
            cy: 10,
          },
        },
        'start'
      )
      .to(
        children[5],
        {
          attr: {
            cx: 40,
            cy: 10,
          },
        },
        'start'
      )
      .to(
        children[7],
        {
          attr: {
            cx: 10,
            cy: 40,
          },
        },
        'start'
      )
      .to(
        children[8],
        {
          attr: {
            cx: 40,
            cy: 40,
          },
        },
        'start'
      )
  })

  useEffect(() => {
    if (isActive) {
      tlSvg.current.play()
      tlDots.current.play()
    } else {
      tlSvg.current.reverse()
      tlDots.current.reverse()
    }
  }, [isActive])

  // --------------------- ===
  //  RENDER
  // ---------------------
  return (
    <button type="button" onClick={onClick} className="flex">
      <svg ref={svg} viewBox="0 0 50 50">
        <line
          data-name="top"
          className={styles.line}
          x1="25"
          y1="5"
          x2="25"
          y2="5"
        />
        <line
          data-name="right"
          className={styles.line}
          x1="45"
          y1="25"
          x2="45"
          y2="25"
        />
        <line
          data-name="bottom"
          className={styles.line}
          x1="25"
          y1="45"
          x2="25"
          y2="45"
        />
        <line
          data-name="left"
          className={styles.line}
          x1="5"
          y1="25"
          x2="5"
          y2="25"
        />
        <circle className={styles.circle} cx="5" cy="5" r="5" />
        <circle className={styles.circle} cx="45" cy="5" r="5" />
        <circle className={styles.circle} cx="25" cy="25" r="5" />
        <circle className={styles.circle} cx="5" cy="45" r="5" />
        <circle className={styles.circle} cx="45" cy="45" r="5" />
      </svg>
      <SrOnly label={label} />
    </button>
  )
}
